<template>
  <div class="date-filter" :style="{ 'min-width': showCheckboxOptions ? '450px': '250px' }">
    <b-form-checkbox-group
      v-if="showCheckboxOptions"
      v-model="dateCheckModel"
      :options="dateOptions"
      class="mr-1 text-nowrap"
    />
    <b-input-group class="input-group-merge">
      <flat-pickr
        v-model="dateFilter"
        :config="config"
        :placeholder="`${placeholder} 선택`"
        class="form-control"
      />
      <b-input-group-append is-text>
        <feather-icon
          v-show="dateFilter!=defaultDateRange"
          class="cursor-pointer"
          icon="RefreshCwIcon"
          @click="dateFilter=defaultDateRange"
        />
      </b-input-group-append>
    </b-input-group>
  </div>

</template>
<script>

import {
  BInputGroup, BInputGroupAppend, BFormCheckboxGroup,
} from 'bootstrap-vue'
import { computed, inject } from '@vue/composition-api'
import { Korean } from 'flatpickr/dist/l10n/ko'
import flatPickr from 'vue-flatpickr-component'
import useDate from '@/views/utils/useDate'

export default {
  components: {
    flatPickr,
    BInputGroup,
    BInputGroupAppend,
    BFormCheckboxGroup,
  },
  props: {
    showCheckboxOptions: {
      type: Boolean,
      default: false,
    },
    placeholder: {
      type: String,
      default: '날짜범위',
    },
  },
  setup() {
    const { getDateRangeFromToday, dateRangeConjunction } = useDate()
    const dateFilter = inject('dateFilter')
    const defaultDateRange = inject('defaultDateRange')
    const dateOptions = [
      { text: '최근 7일', value: getDateRangeFromToday(7, dateRangeConjunction) },
      { text: '최근 30일', value: getDateRangeFromToday(30, dateRangeConjunction) },
    ]

    const config = computed(() => ({
      wrap: true,
      mode: 'range',
      locale: Korean,
      conjunction: dateRangeConjunction,
    }))

    const dateCheckModel = computed({
      get() {
        return [dateFilter.value]
      },
      set(checked) {
        const lastChecked = checked[checked.length - 1]
        dateFilter.value = lastChecked ?? defaultDateRange
        return [dateFilter.value]
      },
    })

    return {
      config,
      dateFilter,
      dateOptions,
      dateCheckModel,
      defaultDateRange,
    }
  },
}
</script>

<style lang="scss">
@import '~@core/scss/vue/libs/vue-flatpicker.scss';
.date-filter {
  margin-right: auto;
  display: inline-flex;
  align-items: center;
}
</style>
