import {
  computed, ref, readonly, provide,
} from '@vue/composition-api'
import { useUtils as useI18nUtils } from '@core/libs/i18n'
import useDate from '@/views/utils/useDate'
import { useRouter } from '@core/utils/utils'
import { sortCompare } from '@/@fake-db/utils'

export default function useCampaignList() {
  const { route, router } = useRouter()
  const { t } = useI18nUtils()
  const { isClosed } = useDate()
  const currentCampaigns = ref([])
  const statusFilter = ref(null)
  const channelFilter = ref([])
  const adFilter = ref([])
  const expandIndexes = ref([])
  const currentPage = ref(1)
  const sortBy = ref('')
  const isSortDirDesc = ref(true)

  const showAccepted = computed({
    get() {
      return !route.value.query.unAccepted
    },
    set(value) {
      if (value) {
        router.push({ name: route.value.name })
      } else {
        router.push({ name: route.value.name, query: { unAccepted: true } })
      }
    },
  })

  const setCurrentCampaign = campaigns => {
    const parsed = []
    campaigns.forEach(campaign => {
      const copied = { ...campaign }
      copied.status = isClosed(campaign.end) && campaign.reviewStatus === 'ACCEPTED' ? 'CLOSED' : campaign.status

      const details = []
      campaign.channelAccountAnalytics.forEach(el => {
        const index = details.findIndex(d => d.name === el.name)
        if (index === -1) {
          details.push({ ...el, clickRate: (el.click / el.impression) * 100 })
        } else {
          const existing = details[index]
          existing.value += el.value
          existing.click += el.click
          existing.impression += el.impression
          existing.clickRate = (existing.click / existing.impression) * 100
        }
      })
      const value = details.reduce((prev, current) => prev + current.value, 0)
      const click = details.reduce((prev, current) => prev + current.click, 0)
      const impression = details.reduce((prev, current) => prev + current.impression, 0)
      if (details.length > 1) {
        const result = [
          {
            ...copied,
            isParent: true,
            children: details,
            hasChildren: true,
            numOfChildren: details.length,
            value,
            click,
            impression,
            clickRate: (click / impression) * 100,
          },
          ...details.map(el => ({
            isParent: false,
            parent: copied,
            hasChildren: false,
            channel: el.name,
            value: el.value,
            click: el.click,
            impression: el.impression,
            clickRate: el.clickRate,
          })),
        ]
        parsed.push(...result)
      } else {
        const detail = details[0]
        parsed.push({
          ...copied,
          isParent: true,
          hasChildren: false,
          channel: detail.name,
          value,
          click,
          impression,
          clickRate: (click / impression) * 100,
        })
      }
    })
    currentCampaigns.value = parsed
  }

  const items = computed(() => {
    let data = currentCampaigns.value
    if (showAccepted.value) {
      data = data.filter(campaign => {
        if (campaign.isParent) {
          return campaign.reviewStatus === 'ACCEPTED'
            && campaign.status === (statusFilter.value || campaign.status)
        }
        return campaign.parent.reviewStatus === 'ACCEPTED'
          && campaign.parent.status === (statusFilter.value || campaign.parent.status)
      })
    } else {
      data = data.filter(campaign => {
        if (campaign.isParent) {
          return campaign.reviewStatus !== 'ACCEPTED'
            && campaign.reviewStatus === (statusFilter.value || campaign.reviewStatus)
        }
        return campaign.parent.reviewStatus !== 'ACCEPTED'
        && campaign.parent.reviewStatus === (statusFilter.value || campaign.parent.reviewStatus)
      })
    }

    if (adFilter.value.length > 0) {
      let result = []
      adFilter.value.forEach(adName => {
        result = [
          ...result,
          ...data.filter(campaign => {
            if (campaign.isParent) {
              return campaign.adAccount.name === adName
            }
            return campaign.parent.adAccount.name === adName
          }),
        ]
      })
      data = result
    }

    if (channelFilter.value.length > 0) {
      if (channelFilter.value.length === 1) {
        // 채널이 1개만 선택된 경우 : 펼쳐보기 기능 제거
        const channelName = channelFilter.value[0]
        data = data.filter(el => el.isParent).filter(campaign => {
          if (campaign.hasChildren) {
            return campaign.children.filter(el => el.name === channelName).length > 0
          }
          return campaign.channel === channelName
        })
        data = data.map(el => {
          if (el.hasChildren) {
            const child = el.children.find(c => c.name === channelName)
            const copied = { ...el }
            copied.hasChildren = false
            copied.channel = child.name
            copied.value = child.value
            copied.click = child.click
            copied.impression = child.impression
            copied.clickRate = child.clickRate
            return copied
          }
          return el
        })
      } else {
        // 2개 이상의 채널이 선택된 경우 해당하는 채널들만 모아서 새로 데이터 생성
        const parentsWithoutChildren = data.filter(el => el.isParent && !el.hasChildren && channelFilter.value.includes(el.channel))
        const children = data.filter(el => !el.isParent && channelFilter.value.includes(el.channel))
        const parentsWithChildren = []
        children.forEach(el => {
          const index = parentsWithChildren.findIndex(d => d.id === el.parent.id)
          if (index === -1) {
            const copied = { ...el.parent }
            copied.isParent = true
            copied.hasChildren = true
            copied.children = [el]
            copied.numOfChildren = 1
            copied.value = el.value
            copied.click = el.click
            copied.impression = el.impression
            copied.clickRate = el.clickRate
            parentsWithChildren.push(copied)
          } else {
            const existing = parentsWithChildren[index]
            existing.numOfChildren += 1
            existing.children.push(el)
            existing.value += el.value
            existing.click += el.click
            existing.impression += el.impression
            existing.clickRate = (existing.click / existing.impression) * 100
          }
        })
        data = [...parentsWithChildren, ...parentsWithoutChildren, ...children]
      }
    }

    if (expandIndexes.value.length > 0) {
      data = data.filter(e => {
        if (e.isParent) {
          return true
        }
        return expandIndexes.value.findIndex(id => id === e.parent.id) !== -1
      })
    } else {
      data = data.filter(e => e.isParent)
    }

    const parents = data.filter(e => e.isParent)
    const sortedData = parents.sort(sortCompare(sortBy.value))
    if (isSortDirDesc.value) {
      sortedData.reverse()
    }

    const result = []
    sortedData.forEach(el => {
      result.push(el)
      result.push(...data.filter(e => e.parent?.id === el.id))
    })

    return result
  })

  const statusOptions = computed(() => {
    if (showAccepted.value) {
      const accepted = currentCampaigns.value.filter(campaign => campaign.isParent && campaign.reviewStatus === 'ACCEPTED')
      const activated = accepted.filter(campaign => campaign.status === 'ACTIVATED')
      const paused = accepted.filter(campaign => campaign.status === 'PAUSED')
      const closed = accepted.filter(campaign => campaign.status === 'CLOSED')
      return [
        {
          text: `${t('Accepted Campaign')} (${accepted.length})`,
          value: null,
        },
        {
          text: `${t('Activated Campaign')} (${activated.length})`,
          value: 'ACTIVATED',
        },
        {
          text: `${t('Paused Campaign')} (${paused.length})`,
          value: 'PAUSED',
        },
        {
          text: `${t('Closed Campaign')} (${closed.length})`,
          value: 'CLOSED',
        },
      ]
    }
    const unAccepted = currentCampaigns.value.filter(el => el.isParent && el.reviewStatus !== 'ACCEPTED')
    const pending = unAccepted.filter(campaign => campaign.reviewStatus === 'PENDING')
    const rejected = unAccepted.filter(campaign => campaign.reviewStatus === 'REJECTED')
    return [
      {
        text: `${t('Unaccepted Campaign')} (${unAccepted.length})`,
        value: null,
      },
      {
        text: `${t('PENDING')} (${pending.length})`,
        value: 'PENDING',
      },
      {
        text: `${t('REJECTED')} (${rejected.length})`,
        value: 'REJECTED',
      },
    ]
  })

  const channelOptions = computed(() => {
    const channels = currentCampaigns.value.filter(cam => !cam.hasChildren).map(cam => cam.channel)
    return [...new Set(channels.flat())]
  })

  const adOptions = computed(() => {
    const ads = currentCampaigns.value.filter(cam => cam.isParent).map(cam => cam.adAccount.name)
    return [...new Set(ads.flat())]
  })

  const reversedOption = computed(() => {
    if (showAccepted.value) {
      const unAccepted = currentCampaigns.value.filter(c => c.isParent && c.reviewStatus !== 'ACCEPTED')
      return `${t('Unaccepted Campaign')} (${unAccepted.length})`
    }
    const accepted = currentCampaigns.value.filter(c => c.isParent && c.reviewStatus === 'ACCEPTED')
    return `${t('Accepted Campaign')} (${accepted.length})`
  })

  // *===============================================---*
  // *--------- Provide --------------------------------*
  // *===============================================---*
  // for CampaignViewHeader
  provide('showAccepted', showAccepted)
  provide('statusOptions', readonly(statusOptions))
  provide('reversedOption', readonly(reversedOption))
  provide('statusFilter', statusFilter)
  provide('channelOptions', readonly(channelOptions))
  provide('adOptions', readonly(adOptions))
  provide('adFilter', adFilter)
  provide('channelFilter', channelFilter)

  // for CampaignViewTable & Footer
  provide('items', readonly(items))
  provide('expandIndexes', expandIndexes)
  provide('currentPage', currentPage)
  provide('sortBy', sortBy)
  provide('isSortDirDesc', isSortDirDesc)

  // common use for fetching data
  provide('setCurrentCampaign', setCurrentCampaign)

  return {
    setCurrentCampaign,
  }
}
