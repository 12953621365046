<template>
  <div>
    <loading-component
      v-if="loading"
    />
    <error-component
      v-else-if="error"
      :error="error"
    />
    <app-table-view v-else>
      <template #header>
        <campaign-list-header />
      </template>
      <template #table>
        <campaign-list-table />
      </template>
    </app-table-view>
  </div>
</template>

<script>
import {
  onMounted, provide, ref, watch,
} from '@vue/composition-api'
import useCampaignTask from '@/views/utils/task/useCampaignTask'
import useCampaignList from '@/views/utils/campaign/useCampaignList'
import AppTableView from '@/views/layouts/AppTableView.vue'
import CampaignListTable from '@/views/components/table/tables/CampaignListTable.vue'
import CampaignListHeader from '@/views/components/table/headers/CampaignListHeader.vue'
import useDate from '@/views/utils/useDate'

export default {
  components: {
    AppTableView,
    CampaignListHeader,
    CampaignListTable,
  },
  setup() {
    const {
      loading,
      error,
      fetchCampaignList,
    } = useCampaignTask()
    const {
      setCurrentCampaign,
    } = useCampaignList()
    const { parseDateRange, getDateRangeFromToday, dateRangeConjunction } = useDate()
    const defaultDateRange = getDateRangeFromToday(7, dateRangeConjunction)
    const dateFilter = ref(defaultDateRange)
    // provide date filter data for DateRangeFilter component
    provide('dateFilter', dateFilter)
    provide('defaultDateRange', defaultDateRange)

    // provide search input data for SearchBar component
    const searchQuery = ref('')
    provide('searchQuery', searchQuery)

    watch([searchQuery, dateFilter], async () => {
      const campaigns = await fetchCampaignList(searchQuery.value, ...parseDateRange(dateFilter.value))
      setCurrentCampaign(campaigns)
    })

    onMounted(async () => {
      const campaigns = await fetchCampaignList()
      setCurrentCampaign(campaigns)
    })

    return {
      loading,
      error,
    }
  },
}
</script>
