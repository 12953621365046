<template>
  <div>
    <div class="header-row">
      <filter-group-toggle/>
    </div>
    <div class="header-row">
      <!-- channel name filter-->
      <div class="select-option">
        <v-select
          v-model="channelFilter"
          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
          multiple
          label="name"
          placeholder="채널명 검색"
          :options="channelOptions"
        />
      </div>

      <!-- Search Bar -->
      <search-bar
        placeholder="캠페인명 검색"
      />
      <!-- date filter-->
      <date-range-filter
        placeholder="집행기간"
      />

      <!-- Add Button -->
      <b-button
        v-if="!isReadOnly"
        class="add-btn text-nowrap"
        variant="primary"
        @click="register"
      >
        <b-icon
          icon="plus"
        />
        캠페인 등록
      </b-button>
    </div>
  </div>
</template>
<script>

import {
  BButton, BIcon,
} from 'bootstrap-vue'
import { computed, inject } from '@vue/composition-api'
import vSelect from 'vue-select'
import router from '@/router'
import { adRouteNames } from '@/router/routes/ad'
import { canWriteCampaign } from '@/libs/acl/protection'
import DateRangeFilter from '@/views/components/table/DateRangeFilter.vue'
import SearchBar from '@/views/components/table/SearchBar.vue'
import FilterGroupToggle from '@/views/components/table/FilterGroupToggle.vue'

export default {
  components: {
    BIcon,
    vSelect,
    FilterGroupToggle,
    BButton,
    SearchBar,
    DateRangeFilter,
  },
  setup() {
    const showAccepted = inject('showAccepted')
    const reversedOption = inject('reversedOption')
    const channelOptions = inject('channelOptions')
    const channelFilter = inject('channelFilter')

    const register = () => {
      router.push({ name: adRouteNames.registerCampaign })
    }

    const isReadOnly = computed(() => !canWriteCampaign())

    return {
      channelOptions,
      channelFilter,
      showAccepted,
      register,
      isReadOnly,
      reversedOption,
    }
  },
}
</script>

<style lang="scss" scoped>
@import '~flatpickr/dist/flatpickr.css';
</style>
